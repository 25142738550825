export var SearchList = [
  { labe: '供应商编码', code: 'supplierCode', type: 'input' },
  { labe: '审核年度', code: 'auditYear', type: 'input' }
  // {
  //   labe: '是否合格',
  //   code: 'Type',
  //   type: 'select',
  //   option: [
  //     { label: '全部', value: '' },
  //     { label: '合格', value: '1' },
  //     { label: '不合格', value: '2' }
  //   ]
  // }
]
export var SearchData = {
  supplier: '',
  auditYear: '',
  Type: ''
}
export var tableField = [
  {
    label: '序号',
    code: '',
    type: 'function',
    width: '60',
    handle: (index) => {
      return index + 1
    }
  },
  { label: '供应商编码', code: 'supplierCode', type: 'input', width: '' },
  { label: '供应商名称', code: 'supplierName', type: 'input' },
  { label: '审核年度', code: 'auditYear', type: 'input', width: '' },
  // { label: '评分项', code: 'name', type: 'input', width: '' },
  { label: '得分', code: 'scoreNum', type: 'input', width: '' },
  // { label: '合格率', code: 'hgl', type: 'input', width: '' },
  // {
  //   label: '是否合格',
  //   code: 'Type',
  //   type: 'function',
  //   width: '',
  //   handle: (index, data) => {
  //     return data.Type === '1' ? '合格' : '不合格'
  //   }
  // },
  // { label: '评分明细', code: '', type: 'input', width: '' },
  { label: '备注', code: 'bz', type: 'input', width: '' }
]
export var tableData = [
]

export var menudata = [
]
